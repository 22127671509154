<template>
    <div class="ren-action-dropdown">
            <b-dropdown
                no-caret
                toggle-class="p-0"
                variant="link"
                dropright
            >
                <template #button-content>
                    <b-button variant="outline-secondary" class="ren-action-btn" size="sm">
                        <rt-icon icon="ellipsis-vertical" variant="light" :size="16"/>
                    </b-button>
                </template>
                <b-dropdown-item
                    v-if="itemId"
                    link-class="d-flex align-items-center"
                    :to="{name: componentName, params: { id: itemId }}">
                    <RtIcon variant="light" icon="edit" :size="16"/>
                    <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                </b-dropdown-item>
                <div v-else>
                    <b-dropdown-item
                        link-class="d-flex align-items-center"
                        @click="onSave">
                        <rt-icon icon="circle-check" variant="light" :size="16"/>
                        <span class="align-middle ml-50">{{ i18nT(`Sauvegarder`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        link-class="d-flex align-items-center"
                        :to="{name: componentName}">
                        <rt-icon icon="circle-x" variant="light" :size="16"/>
                        <span class="align-middle ml-50">{{ i18nT(`Annuler`) }}</span>
                    </b-dropdown-item>
                </div>
                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    v-if="showDeleteBtn"
                    @click="onDelete"
                >
                    <rt-icon icon="trash text-danger" variant="light" :size="16"/>
                    <span
                        class="align-middle ml-50 text-danger"
                    >{{
                            i18nT(`Supprimer`)
                        }}</span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
</template>

<script>

import {
    BFormGroup,
    BButton,
    BDropdownItem,
    BDropdown,
    VBTooltip,
} from 'bootstrap-vue'

export default {
    components: {
        BDropdown,
        BDropdownItem,
        BButton,
        BFormGroup,
    },
    props: {
        componentName: {
            type: String,
            default: ''
        },
        showDeleteBtn: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: [String, Number],
            default: ''
        },
    },
    methods: {
        onSave() {
            this.$emit('save')
        },
        onDelete() {
            this.$emit('delete')
        }
    }

}

</script>

<style>

.ren-action-dropdown {
    margin-top: -20px;
    text-align: right;
}

.ren-action-dropdown .dropdown {
    margin-top: -50px;
}

.ren-action-btn {
    padding: 2px;
}

</style>